import {
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader,
  IonPage,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { refreshOutline } from "ionicons/icons";
import { Plugins } from "@capacitor/core";

interface OneData {
  _id: String;
  public_ip: String;
  local_ip: String;
  display_id: Number;
  esp_uid: Number;
  created_at: Number;
  display_name: String;
}

const Home: React.FC = () => {
  const [responseData, setresponseData] = useState<OneData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    parseData();
  }, []);
  const parseData = async () => {
    setLoading(true);
    const response = await axios
      .get("https://api.find.nsd.toastbrot.org/me", {})
      .then((r) => {
        const response: {
          _id: String;
          public_ip: String;
          local_ip: String;
          display_id: Number;
          esp_uid: Number;
          created_at: Number;
          display_name: String;
        }[] = r.data;
        return response;
      });
    setresponseData(response);
    setLoading(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Nightscout Display Finder</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">NSD Finder</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonItem>Klicke auf eines der folgenden Displays</IonItem>
        <IonListHeader>Gefundene Displays</IonListHeader>
        <IonList>
          {!loading ? (
            responseData.length === 0 ? (
              <IonItem>
                Anscheinend hast du kein Display oder es ist ausgeschaltet
              </IonItem>
            ) : (
              responseData.map((e) => (
                <OneItem
                  display_id={e.display_id}
                  ip_addr={e.local_ip}
                  display_name={e.display_name}
                />
              ))
            )
          ) : (
            <IonItem>
              <IonSkeletonText />
            </IonItem>
          )}
          {}
        </IonList>
        <IonFab slot="fixed" horizontal="end" vertical="bottom">
          <IonFabButton onClick={() => parseData()}>
            <IonIcon icon={refreshOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

const OneItem: React.FC<{
  display_id: Number;
  ip_addr: String;
  display_name: String;
}> = (props) => {
  return (
    // <IonItem detail href={"http://" + props.ip_addr + "/"}>
    //   display{props.display_id}
    // </IonItem>

    <IonItem
      detail
      button
      onClick={() =>
        Plugins.Browser.open({ url: "http://" + props.ip_addr + "/" })
      }>
      display{props.display_id}{" "}
      {props.display_name !== "" ? "(" + props.display_name + ")" : ""}
    </IonItem>
  );
};

export default Home;
